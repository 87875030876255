import { FastCompany, MensHealth, Time } from 'components/_const_assets/companyLogos'
import { AwardProps, CardProps } from './types'
import { FocusContent, HealthContent, PerformanceContent } from 'components/Phantom/_sections/SlideOutBenefits/ContentChunks'

export const getDefaultCards = (): CardProps[] => {
	return [Focus, Health, Performance]
}

export const getDefaultAwards = (): AwardProps[] => {
	return [FastCompanyProps, MensHealthProps, TimeProps]
}

const Health: CardProps = {
	image: {
		src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/slide-out-assets/health-bed.png',
		alt: 'A man sleeping soundly in a cooly lit bedroom',
	},

	tag: 'Health',
	blurb: `Optimize your cardiovascular recovery to enhance longevity`,

	title: 'The Pod has been clinically proven to improve cardiovascular recovery by up to 17%.',
	cta: {
		text: 'Read clinical study',
		href: '/pod-cover',
	},

	content: <HealthContent />,

	img_src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/benefits-slide-xyz-1.jpg',
}

const Focus: CardProps = {
	image: {
		src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/slide-out-assets/laptop-focus.png',
		alt: 'Hands typing on a laptop on a coffee table. There is a pen and warm cup of coffee on the table.',
	},

	tag: 'Focus',
	blurb: 'Sharpen your attention and short-term memory',

	title: 'The Pod has been clinically proven to improve cognitive function by 13%.',
	cta: {
		text: 'Read clinical study',
		href: '/pod-cover',
	},

	content: <FocusContent />,

	img_src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/benefits-slide-xyz-2-v2.jpg',
}

const Performance: CardProps = {
	image: {
		src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/slide-out-assets/performance_bike.png',
		alt: 'A man on a bike with motion blur effects',
	},

	tag: 'Performance',
	blurb: 'Boost your energy and athletic recovery',

	title: 'The Pod has been reported to boost energy levels by 34%.',
	cta: {
		text: 'Read clinical study',
		href: '/pod-cover',
	},

	content: <PerformanceContent />,

	img_src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/benefits-slide-xyz-3-v2.jpg',
}

const FastCompanyProps: AwardProps = {
	year: '2019, 2022 & 2023',
	title: 'Most Innovative Companies – Wellness',
	logo: FastCompany('white'),
}

const MensHealthProps: AwardProps = {
	year: '2021',
	title: 'Men’s Health Sleep Award',
	logo: MensHealth('#f70000'),
}

const TimeProps: AwardProps = {
	year: '2018 & 2019',
	title: 'Best Inventions',
	logo: Time('#ff0000'),
}
